import styled from "styled-components";

export const GenericHeadingText = styled.p`
  text-align: ${(props) => props.textAlign || "left"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  float: left;
  margin: 0px auto;
  display: block;
  padding: 0px 10px;
  font-size: ${(props) => props.fontSize || "22px"};
  font-weight: ${(props) => props.fontWeight || "700"};
  line-height: ${(props) => props.lineHeight || "32px"};
  color: ${(props) => props.color || "#000"};
`;

export const GenericHeadingTextWrapper = styled.div`
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 20px;
`;
