import { Routes, Route } from "react-router-dom";
import Sign from "./pages/Sign";
import Main from "./pages/Main";
import Complete from "./pages/Complete";
import Attach from "./pages/Attach";
import New from "./pages/New";
import { UIWrapper } from "./components/ui";
import styled from "styled-components";
import { useEffect, useState } from "react";

const Loading = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  color: white;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  visibility: ${(props) => (props.isLoading ? "visible" : "hidden")};
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  z-index: 999999;
  overflow: hidden;
`;

const LoadingMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 500;
`;

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const defaultProps = {
    setIsLoading,
  };
  useEffect(() => {
    if (
      navigator.userAgent.match(/Android/i) &&
      navigator.userAgent.match(/KAKAOTALK/i)
    ) {
      window.location.href =
        "intent://" +
        window.location.href.replace(/https?:\/\//i, "") +
        "#Intent;scheme=http;package=com.android.chrome;end";
    }
  }, []);
  return (
    <>
      <Loading isLoading={isLoading}>
        <LoadingMessage>Loading...</LoadingMessage>
      </Loading>
      <UIWrapper>
        <Routes>
          <Route path="/new" element={<New {...defaultProps} />} exact />
          <Route
            path="/complete"
            element={<Complete {...defaultProps} />}
            exact
          />
          <Route
            path="/sign/:id/:authToken"
            element={<Sign {...defaultProps} />}
            exact
          />
          <Route
            path="/:id/:authToken"
            element={<Main {...defaultProps} />}
            exact
          />
          <Route
            path="/attach/:id/:authToken"
            element={<Attach {...defaultProps} />}
            exact
          />
          <Route
            path="*"
            element={<LoadingMessage>404 Page Not Found</LoadingMessage>}
          />
        </Routes>
      </UIWrapper>
    </>
  );
}

export default App;
