import styled from "styled-components";

export const GenericTextInput = styled.input`
  text-align: ${(props) => props.textAlign || "left"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "50px"};
  float: left;
  margin: 0px auto;
  display: block;
  padding: 10px 20px;
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border: 1px solid ${(props) => props.borderColor || "#e6e6e6"};
  border-radius: 2px;
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "400"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    font-weight: 400;
    color: #85858d;
  }
`;
