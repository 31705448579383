import styled from "styled-components";
import pie_logo_black from "../assets/pie_logo_black.svg";
const Logo = styled.img`
  width: 59px;
  margin-right: 10px;
`;

const LogoWrapper = styled.div`
  width: calc(100% - 60px);
  margin: 0px;
  padding: 0px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const HeaderText = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-align: center;
`;

export function Header() {
  return (
    <LogoWrapper>
      <Logo src={pie_logo_black} />
      <HeaderText>크리에이터 전자서명 시스템</HeaderText>
    </LogoWrapper>
  );
}
