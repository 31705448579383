import { useEffect } from "react";
import {
  GenericHeadingText,
  GenericHeadingTextWrapper,
} from "../components/texts";
import styled from "styled-components";
import { GenericButton } from "../components/buttons";
import clap_colorful from "../assets/clap_colorful.svg";
const ContentWrapper = styled.div`
  width: 85%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 50px;
`;
const UserMessage = styled.p`
  color: #535359;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;
const ConfirmButton = styled(GenericButton)`
  position: fixed;
  bottom: 45px;
  left: 0;
  right: 0;
  width: 85%;
  max-width: 425px;
  margin-top: 40px;
  background-color: #b0b1b8;
  color: #fff;
`;
const ClapImg = styled.img`
  width: 55%;
  margin: 0 auto;
  display: block;
  margin-top: ${(window.innerHeight - 550) * 0.5}px;
  margin-bottom: 20px;
`;

const Complete = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.setIsLoading(false);
  }, []);
  return (
    <>
      <GenericHeadingTextWrapper>
        <GenericHeadingText>계약서 작성이</GenericHeadingText>
        <GenericHeadingText>모두 완료되었어요</GenericHeadingText>
      </GenericHeadingTextWrapper>
      <ContentWrapper>
        <UserMessage>파이3D의 크리에이터가 되어 주셔서 감사해요!</UserMessage>
        <UserMessage>
          서명을 완료한 계약서는 내가 입력한 메일로 전송돼요.
        </UserMessage>
        <ClapImg src={clap_colorful} />
        <ConfirmButton
          onClick={() => (window.location.href = "https://www.pie3d.com")}
        >
          종료하기
        </ConfirmButton>
      </ContentWrapper>
    </>
  );
};

export default Complete;
