import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../tools/api";
import {
  GenericHeadingText,
  GenericHeadingTextWrapper,
} from "../components/texts";
import styled from "styled-components";
import { GenericButton } from "../components/buttons";
import plus_image from "../assets/plus.svg";
const ContentWrapper = styled.div`
  width: 85%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 48px;
`;
const EmailMessage = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #535359;
  padding: 0px 10px;
`;
const InputMesage = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #85858d;
  margin: 0 auto;
  width: 325px;
`;
const ConfirmButton = styled(GenericButton)`
  margin-top: 40px;
  :disabled {
    background-color: #b0b1b8;
    color: #fff;
  }
`;
const PreviewImage = styled.div`
  width: 325px;
  height: 178px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 0 auto;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 30px;
`;

const ImageInput = styled.input`
  display: none;
`;

const resizeImage = async (
  imageURL,
  width,
  height,
  retainRatio = false,
  autorotate = null
) => {
  console.log(
    `resizeImage(${imageURL}, ${width}, ${height}, ${retainRatio}, ${autorotate})`
  );
  let imgWidth = width;
  let imgHeight = height;
  let rotation = 0;

  const image = new Image();
  image.src = imageURL;

  await new Promise((resolve) => {
    image.onload = () => {
      if (autorotate === "portrait" && image.width > image.height) {
        imgWidth = image.height;
        imgHeight = image.width;
        rotation = 90;
      } else if (autorotate === "landscape" && image.width < image.height) {
        imgWidth = image.height;
        imgHeight = image.width;
        rotation = 90;
      } else {
        imgWidth = image.width;
        imgHeight = image.height;
      }
      resolve();
    };
  });

  const canvas = document.createElement("canvas");
  let scale;
  if (retainRatio) {
    scale = Math.min(width / imgWidth, height / imgHeight);
    canvas.width = imgWidth * scale;
    canvas.height = imgHeight * scale;
  } else {
    canvas.width = width;
    canvas.height = height;
  }
  canvas.style.backgroundColor = "#fff";
  const ctx = canvas.getContext("2d");

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.translate(-canvas.width / 2, -canvas.height / 2);

  if (!retainRatio) {
    scale = Math.max(width / imgWidth, height / imgHeight);
  }

  const x = canvas.width / 2 - (image.width / 2) * scale;
  const y = canvas.height / 2 - (image.height / 2) * scale;
  ctx.drawImage(image, x, y, image.width * scale + 1, image.height * scale + 1);

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }
      resolve({
        image: blob,
        ratio: canvas.height / canvas.width,
      });
    }, "image/jpeg");
  });
};

const Main = (props) => {
  const { id, authToken } = useParams();
  const navigate = useNavigate();
  const [passbookImage, setPassbookImage] = useState(plus_image);
  const [idcardImage, setIdcardImage] = useState(plus_image);
  const [passbookPreviewHeight, setPassbookPreviewHeight] = useState("178px");
  const [passbook, setPassbook] = useState(null);
  const [idcard, setIdcard] = useState(null);
  const onConfirmClick = async () => {
    props.setIsLoading(true);
    if (!(passbook && idcard)) {
      return;
    }
    const formData = new FormData();
    formData.append("passbook", passbook);
    formData.append("idcard", idcard);
    api
      .post(`/api/${id}?auth_token=${authToken}`, formData)
      .then((data) => {
        navigate(`/complete`);
      })
      .catch((err) => {
        alert("오류가 발생하였습니다.");
      });
  };
  const passbookRef = useRef(null);
  const idcardRef = useRef(null);
  const handleIdcardChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const newImage = await resizeImage(
      URL.createObjectURL(file),
      3250,
      1780,
      false,
      "landscape"
    );
    const newImageUrl = URL.createObjectURL(newImage.image);
    setIdcard(newImage.image);
    setIdcardImage(newImageUrl);
  };
  const handlePassbookChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const newImage = await resizeImage(
      URL.createObjectURL(file),
      3250,
      1780,
      true
    );
    console.log(newImage);
    const newImageUrl = URL.createObjectURL(newImage.image);
    setPassbookPreviewHeight(`${325 * newImage.ratio}px`);
    setPassbook(newImage.image);
    setPassbookImage(newImageUrl);
  };

  useEffect(() => {
    props.setIsLoading(true);
    api
      .get(`/api/${id}?auth_token=${authToken}`)
      .then((resp) => {
        const data = resp.data;
        if (data.status) {
          if (data.status === "PENDING_SIGN") {
            navigate(`/sign/${id}/${authToken}`);
            return;
          } else if (data.status === "PENDING_USER_DATA") {
            navigate(`/${id}/${authToken}`);
            return;
          } else if (data.status === "COMPLETE") {
            navigate(`/complete`);
            return;
          }
        }
        props.setIsLoading(false);
      })
      .catch((err) => {
        alert(
          "요청 중 오류가 발생하였습니다.\n만료되었거나 존재하지 않는 링크일 수 있습니다.\n잠시 후 다시 시도하거나 고객센터로 문의해주세요."
        );
        props.setIsLoading(false);
      });
  }, []);
  return (
    <>
      <GenericHeadingTextWrapper>
        <GenericHeadingText>크리에이터 본인 확인을 위해</GenericHeadingText>
        <GenericHeadingText>
          신분증과 통장 사본을 준비해주세요
        </GenericHeadingText>
      </GenericHeadingTextWrapper>
      <ContentWrapper>
        <EmailMessage>파일을 잘못 첨부하였을 경우 </EmailMessage>
        <EmailMessage>MD를 통해 다시 제출할 수 있습니다. </EmailMessage>
        <PreviewImage
          src={idcardImage}
          style={{
            backgroundSize: idcardImage === plus_image ? "" : "contain",
          }}
          onClick={() => {
            idcardRef.current.click();
          }}
        />
        <InputMesage>실명을 확인할 수 있는 신분증을 첨부해 주세요.</InputMesage>
        <InputMesage>(주민등록증, 운전면허증, 여권 등)</InputMesage>
        <PreviewImage
          src={passbookImage}
          style={{
            backgroundSize: passbookImage === plus_image ? "" : "contain",
            height: passbookPreviewHeight,
          }}
          onClick={() => {
            passbookRef.current.click();
          }}
        />
        <InputMesage>통장 사본을 첨부해주세요.</InputMesage>
        <ConfirmButton
          onClick={onConfirmClick}
          disabled={!(passbook && idcard)}
        >
          첨부 완료
        </ConfirmButton>
        <ImageInput
          ref={idcardRef}
          type={"file"}
          accept={".jpg, .jpeg, .png"}
          onChange={handleIdcardChange}
        />
        <ImageInput
          ref={passbookRef}
          type={"file"}
          accept={".jpg, .jpeg, .png"}
          onChange={handlePassbookChange}
        />
      </ContentWrapper>
    </>
  );
};

export default Main;
