import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../tools/api";
import SignaturePad from "signature_pad";
import styled from "styled-components";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  GenericHeadingTextWrapper,
  GenericHeadingText,
} from "../components/texts";
import "swiper/css";
import "swiper/css/navigation";
import arrow_left from "../assets/arrow_left.svg";
import arrow_right from "../assets/arrow_right.svg";
import { Zoom, Navigation } from "swiper";
import { GenericButton } from "../components/buttons";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const SignPadProps = {
  width: 320,
  height: 190,
  style: {
    border: "none",
    backgroundColor: "#F5F7F8",
    borderRadius: "4px",
    marginTop: "25px",
  },
};

const SignPadWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  width: 85%;
  height: fit-content;
  margin: 0 auto;
`;
const PageSizeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PageSizeText = styled.div`
  color: #85858d;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;

const SignMessage = styled.span`
  color: #85858d;
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
  margin-top: 10px;
  display: inline-block;
`;
const ConfirmButton = styled(GenericButton)`
  margin-top: 10px;
`;
const ResetButton = styled(GenericButton)`
  margin-top: 20px;
  background-color: #fff;
  color: #ff8838;
  border: 1px solid #e8e9ed;
`;

const SwiperWrapper = styled.div`
  .swiper-button-prev {
    background: url(${arrow_left}) no-repeat;
    background-position: center;
    background-size: 36px;
    width: 36px;
    height: 36px;
    margin-left: 15px;
  }
  .swiper-button-prev::after {
    display: none;
  }
  .swiper-button-next {
    background: url(${arrow_right}) no-repeat;
    background-position: center;
    background-size: 36px;
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }
  .swiper-button-next::after {
    display: none;
  }
`;
const Sign = (props) => {
  const { id, authToken } = useParams();
  const navigate = useNavigate();
  const [signPad, setSignPad] = useState(null);
  const signaturePadRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [swiperContents, setSwiperContents] = useState(null);
  const [pdfname, setPdfname] = useState(null);
  const swiperRef = useRef(null);
  const getWidth = () => {
    if (document.body.clientWidth > 500) {
      return 500;
    } else {
      return document.body.clientWidth;
    }
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    const SwiperContent = Array.from(new Array(numPages), (el, index) => (
      <SwiperSlide key={index}>
        <Page pageNumber={index + 1} width={getWidth()} />
      </SwiperSlide>
    ));
    setSwiperContents(SwiperContent);
    props.setIsLoading(false);
  };
  const onSubmit = async () => {
    props.setIsLoading(true);
    if (signPad.isEmpty()) {
      alert("서명을 입력해주세요.");
      props.setIsLoading(false);
      return;
    }
    const form = new FormData();
    const dataUrl = signPad.toDataURL();
    const blob = await fetch(dataUrl).then((r) => r.blob());
    form.append("sign", blob);
    const response = await api.post(`/api/${id}?auth_token=${authToken}`, form);
    if (response.data.status) {
      if (response.data.status === "COMPLETE") {
        navigate(`/complete`);
        return;
      } else if (response.data.status === "PENDING_IMAGE_UPLOAD") {
        navigate(`/attach/${id}/${authToken}`);
        return;
      }
    }
  };
  useEffect(() => {
    props.setIsLoading(true);
    api
      .get(`/api/${id}?auth_token=${authToken}`)
      .then((response) => {
        if (response.data.status) {
          if (response.data.status === "COMPLETE") {
            navigate(`/complete`);
            return;
          } else if (response.data.status === "PENDING_IMAGE_UPLOAD") {
            navigate(`/attach/${id}/${authToken}`);
            return;
          } else if (response.data.status === "PENDING_USER_DATA") {
            navigate(`/${id}/${authToken}`);
            return;
          }
        }
        if (response.status === 200) {
          setPdfname(response.data.preview_pdf_url);
        }
      })
      .catch((error) => {
        alert(
          `오류가 발생하였습니다.\n고객센터에 문의해주세요.\n(${error.response.data.error})`
        );
      });
    setSignPad(new SignaturePad(signaturePadRef.current));
  }, [signaturePadRef, authToken, id]);
  return (
    <>
      <GenericHeadingTextWrapper>
        <GenericHeadingText>계약서를 확인하고</GenericHeadingText>
        <GenericHeadingText>서명을 완료해 주세요</GenericHeadingText>
      </GenericHeadingTextWrapper>
      <SwiperWrapper>
        <Document
          file={pdfname}
          onLoadSuccess={onDocumentLoadSuccess}
          width={getWidth()}
        >
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            onSlideChange={(e) => setPageNumber(e.realIndex + 1)}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Zoom, Navigation]}
            navigation={true}
            ref={swiperRef}
            style={{ width: getWidth() }}
          >
            {swiperContents}
          </Swiper>
        </Document>
      </SwiperWrapper>
      <ContentWrapper>
        <SignPadWrapper>
          <PageSizeWrapper>
            <PageSizeText
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                window.open(pdfname, "_blank");
              }}
            >
              계약서 전문 보기 (새창)
            </PageSizeText>
            <PageSizeText>
              {pageNumber} / {numPages}
            </PageSizeText>
          </PageSizeWrapper>
          <canvas {...SignPadProps} ref={signaturePadRef} />
          <SignMessage>계약서를 모두 읽고 서명을 완료해 주세요.</SignMessage>
        </SignPadWrapper>
        <ResetButton onClick={() => signPad.clear()}>서명 다시하기</ResetButton>
        <ConfirmButton onClick={onSubmit}>서명 완료</ConfirmButton>
      </ContentWrapper>
    </>
  );
};

export default Sign;
