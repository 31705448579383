import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../tools/api";
import {
  GenericHeadingText,
  GenericHeadingTextWrapper,
} from "../components/texts";
import styled from "styled-components";
import { GenericButton } from "../components/buttons";
import { GenericTextInput } from "../components/inputs";

const ContentWrapper = styled.div`
  width: 85%;
  height: fit-content;
  margin: 0 auto;
`;
const EmailMessage = styled.span`
  color: #85858d;
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
  margin-top: 10px;
  display: inline-block;
`;
const UserDataInput = styled(GenericTextInput)`
  margin-top: ${(props) => props.marginTop || "30px"};
`;
const ConfirmButton = styled(GenericButton)`
  margin-top: 40px;
`;
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const Main = (props) => {
  const { id, authToken } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [idnum, setIdnum] = useState("");
  const [address_1, setAddress1] = useState("");
  const [address_2, setAddress2] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onIdnumChange = (e) => {
    const idnumWithoutHyphen = e.target.value.replace(/[^\d]/g, "");
    if (idnumWithoutHyphen.length > 6) {
      const idnumWithHyphen = `${idnumWithoutHyphen.slice(
        0,
        6
      )}-${idnumWithoutHyphen.slice(6, 13)}`;
      setIdnum(idnumWithHyphen);
    } else {
      setIdnum(idnumWithoutHyphen);
    }
  };
  const onAddressChange = (e) => {
    setAddress1(e.target.value);
  };
  const onDetailedAddressChange = (e) => {
    setAddress2(e.target.value);
  };
  const onPhoneChange = (e) => {
    const phoneWithoutHyphen = e.target.value.replace(/[^\d]/g, "");
    if (phoneWithoutHyphen.length > 3 && phoneWithoutHyphen.length <= 7) {
      setPhone(
        `${phoneWithoutHyphen.slice(0, 3)}-${phoneWithoutHyphen.slice(3, 7)}`
      );
    } else if (phoneWithoutHyphen.length > 7) {
      setPhone(
        `${phoneWithoutHyphen.slice(0, 3)}-${phoneWithoutHyphen.slice(
          3,
          7
        )}-${phoneWithoutHyphen.slice(7, 11)}`
      );
    } else {
      setPhone(phoneWithoutHyphen);
    }
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onConfirmClick = async () => {
    if (name === "테스트") {
      navigate("/sign");
      return;
    }
    if (name === "") {
      alert("이름을 입력해주세요");
      return;
    }
    if (idnum.length !== 14) {
      alert("주민등록번호를 입력해주세요");
      return;
    }
    if (address_1 === "" || address_2 === "") {
      alert("주소를 입력해주세요");
      return;
    }
    if (phone.length !== 13 || !phone.startsWith("010")) {
      alert("휴대폰 번호를 확인해주세요");
      return;
    }
    if (!validateEmail(email)) {
      alert("이메일이 올바르지 않습니다");
      return;
    }
    const postData = {
      name,
      email,
      phone,
      address_1,
      address_2,
      idnum,
    };
    props.setIsLoading(true);
    api
      .post(`/api/${id}?auth_token=${authToken}`, postData)
      .then((data) => {
        navigate(`/sign/${id}/${authToken}`);
      })
      .catch((err) => {
        alert("오류가 발생하였습니다.");
      });
  };
  useEffect(() => {
    props.setIsLoading(true);
    api
      .get(`/api/${id}?auth_token=${authToken}`)
      .then((resp) => {
        const data = resp.data;
        if (data.status) {
          if (data.status === "PENDING_SIGN") {
            navigate(`/sign/${id}/${authToken}`);
            return;
          } else if (data.status === "PENDING_IMAGE_UPLOAD") {
            navigate(`/attach/${id}/${authToken}`);
            return;
          } else if (data.status === "COMPLETE") {
            navigate(`/complete`);
            return;
          }
        }
        if (data.name) {
          setName(data.name);
        }
        if (data.email) {
          setEmail(data.email);
        }
        if (data.phone) {
          const phoneWithoutHyphen = data.phone.replace(/[^\d]/g, "");
          if (phoneWithoutHyphen.length > 3 && phoneWithoutHyphen.length <= 7) {
            setPhone(
              `${phoneWithoutHyphen.slice(0, 3)}-${phoneWithoutHyphen.slice(
                3,
                7
              )}`
            );
          } else if (phoneWithoutHyphen.length > 7) {
            setPhone(
              `${phoneWithoutHyphen.slice(0, 3)}-${phoneWithoutHyphen.slice(
                3,
                7
              )}-${phoneWithoutHyphen.slice(7, 11)}`
            );
          } else {
            setPhone(phoneWithoutHyphen);
          }
        }
        if (data.address_1) {
          setAddress1(data.address_1);
        }
        if (data.address_2) {
          setAddress2(data.address_2);
        }
        props.setIsLoading(false);
      })
      .catch((err) => {
        alert(
          "요청 중 오류가 발생하였습니다.\n만료되었거나 존재하지 않는 링크일 수 있습니다.\n잠시 후 다시 시도하거나 고객센터로 문의해주세요."
        );
        props.setIsLoading(false);
      });
  }, []);
  return (
    <>
      <GenericHeadingTextWrapper>
        <GenericHeadingText>본인확인을 위해</GenericHeadingText>
        <GenericHeadingText>아래의 정보를 입력해주세요</GenericHeadingText>
      </GenericHeadingTextWrapper>
      <ContentWrapper>
        <UserDataInput
          placeholder="이름 (홍길동)"
          value={name}
          onChange={onNameChange}
          maxLength={10}
        />
        <UserDataInput
          placeholder="주민등록번호 (990101-1234567)"
          value={idnum}
          onChange={onIdnumChange}
        />
        <UserDataInput
          placeholder="기본주소 (서울특별시 강남구)"
          value={address_1}
          onChange={onAddressChange}
        />
        <UserDataInput
          marginTop={"8px"}
          placeholder="상세 주소 (테헤란로 111, 101호)"
          value={address_2}
          onChange={onDetailedAddressChange}
        />
        <UserDataInput
          placeholder="휴대폰번호 (010-1234-5678)"
          value={phone}
          onChange={onPhoneChange}
        />
        <UserDataInput
          placeholder="이메일 (support@pie3d.com)"
          value={email}
          onChange={onEmailChange}
        />
        <EmailMessage>
          입력한 이메일 주소로 최종 계약서가 전송됩니다.
        </EmailMessage>
        <ConfirmButton onClick={onConfirmClick}>입력 완료</ConfirmButton>
      </ContentWrapper>
    </>
  );
};

export default Main;
