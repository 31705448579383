import styled from "styled-components";

export const GenericButton = styled.button`
  text-align: ${(props) => props.textAlign || "center"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "50px"};
  float: left;
  margin: 0px auto;
  display: block;
  padding: 10px 20px;
  background-color: ${(props) => props.backgroundColor || "#ff8838"};
  color: ${(props) => props.color || "#fff"};
  border: ${(props) => props.border || "none"};
  border-radius: 4px;
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "500"};
  cursor: ${(props) => props.cursor || "pointer"};
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor || "#f5f5f5"};
    color: ${(props) => props.hoverColor || "#000"};
  }
  &:active {
    background-color: ${(props) => props.activeBackgroundColor || "#f5f5f5"};
    border-color: ${(props) => props.activeBorderColor || "#e6e6e6"};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${(props) => props.disabledBackgroundColor || "#f5f5f5"};
    border-color: ${(props) => props.disabledBorderColor || "#e6e6e6"};
    color: ${(props) => props.disabledColor || "#adadb6"};
  }
`;
