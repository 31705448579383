import styled from "styled-components";
import { Header } from "./header";
export const Margin = styled.div`
  height: ${({ direction, size }) => (direction === "column" ? size : 1)}px;
  width: ${({ direction, size }) => (direction === "column" ? 1 : size)}px;
`;

const MainWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 45px auto;
`;

export function UIWrapper({ children }) {
  return (
    <MainWrapper>
      <Header />
      {children}
    </MainWrapper>
  );
}
