import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../tools/api";
import {
  GenericHeadingText,
  GenericHeadingTextWrapper,
} from "../components/texts";
import styled from "styled-components";
import { GenericButton } from "../components/buttons";
import { GenericTextInput } from "../components/inputs";

const ContentWrapper = styled.div`
  width: 85%;
  height: fit-content;
  margin: 0 auto;
`;
const EmailMessage = styled.span`
  color: #85858d;
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
  margin-top: 10px;
  display: inline-block;
`;
const UserDataInput = styled(GenericTextInput)`
  margin-top: ${(props) => props.marginTop || "30px"};
`;
const ConfirmButton = styled(GenericButton)`
  margin-top: 40px;
`;
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const Main = (props) => {
  const { id, authToken } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onPhoneChange = (e) => {
    const phoneWithoutHyphen = e.target.value.replace(/[^\d]/g, "");
    if (phoneWithoutHyphen.length > 3 && phoneWithoutHyphen.length <= 7) {
      setPhone(
        `${phoneWithoutHyphen.slice(0, 3)}-${phoneWithoutHyphen.slice(3, 7)}`
      );
    } else if (phoneWithoutHyphen.length > 7) {
      setPhone(
        `${phoneWithoutHyphen.slice(0, 3)}-${phoneWithoutHyphen.slice(
          3,
          7
        )}-${phoneWithoutHyphen.slice(7, 11)}`
      );
    } else {
      setPhone(phoneWithoutHyphen);
    }
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onConfirmClick = async () => {
    if (name === "") {
      alert("이름을 입력해주세요");
      return;
    }
    if (phone.length !== 13 || !phone.startsWith("010")) {
      alert("휴대폰 번호를 확인해주세요");
      return;
    }
    if (!validateEmail(email)) {
      alert("이메일이 올바르지 않습니다");
      return;
    }
    const postData = {
      name,
      email,
      phone,
    };
    props.setIsLoading(true);
    api
      .post(`/extapi/create_sign_request`, postData)
      .then((data) => {
        alert("전자계약서가 전송되었습니다.\n이메일을 확인해주세요.");
        window.open("", "_self").close();
      })
      .catch((err) => {
        alert("오류가 발생하였습니다.");
      });
  };
  return (
    <>
      <GenericHeadingTextWrapper>
        <GenericHeadingText>계약서 전송을 위해</GenericHeadingText>
        <GenericHeadingText>아래의 정보를 입력해주세요</GenericHeadingText>
      </GenericHeadingTextWrapper>
      <ContentWrapper>
        <UserDataInput
          placeholder="이름 (홍길동)"
          value={name}
          onChange={onNameChange}
          maxLength={10}
        />
        <UserDataInput
          placeholder="휴대폰번호 (010-1234-5678)"
          value={phone}
          onChange={onPhoneChange}
        />
        <UserDataInput
          placeholder="이메일 (support@pie3d.com)"
          value={email}
          onChange={onEmailChange}
        />
        <EmailMessage>
          입력한 이메일 주소로 전자계약서가 전송됩니다.
        </EmailMessage>
        <ConfirmButton onClick={onConfirmClick}>입력 완료</ConfirmButton>
      </ContentWrapper>
    </>
  );
};

export default Main;
